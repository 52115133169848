import useAryLayoutType from 'framework/base/hooks/useAryLayoutType';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  ApiAuthorizationConstants,
  AuthRouteGuard as AuthGuard,
  LoginCallbackHandler
} from 'spec';

import './assets/fontYekanBakh.css';
import './language/translation';
import appRoutes from './routes';

const { ApplicationPaths } = ApiAuthorizationConstants;

function lazyRoutes(routes, layoutType) {
  if (!routes || routes.length === 0) return null;
  return (
    <>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AuthGuard
              anonymousAccess={route.anonymousAccess}
              authKeys={route.authKeys}
              acceptedRole={route.acceptedRole}
              path={route.path}
              component={
                layoutType === 'mobile' && route.mobile
                  ? route.mobile
                  : route.component
              }
            />
          }
        >
          {lazyRoutes(route.routes, layoutType)}
        </Route>
      ))}
    </>
  );
}

const App = () => {
  const { t } = useTranslation();
  const layoutType = useAryLayoutType();

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`%s | ${t('Ariyana')}`}
        defaultTitle={`${t('Ariyana')} - ${t(
          'Support And Software Development Panel'
        )}`}
      />
      <Routes>
        <>
          <Route
            path="/"
            element={<Navigate replace to="/dashboard/my-tickets" />}
          />
          {lazyRoutes(appRoutes, layoutType)}
        </>
        <Route
          exact
          path={ApplicationPaths.LoginCallback}
          element={<LoginCallbackHandler />}
        />
      </Routes>
    </HelmetProvider>
  );
};

export default App;
