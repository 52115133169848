import { lazy } from 'react';

const routes = [
  {
    path: '/dashboard',
    component: lazy(() => import('./Dashboard')),
    anonymousAccess: true,
    routes: [
      {
        path: 'errors/error-401',
        component: lazy(() => import('./pages/desktop/errors/Error401')),
        mobile: lazy(() => import('./pages/mobile/errors/Error401'))
      },
      {
        path: 'my-tickets',
        component: lazy(() => import('./pages/desktop/chat/ChatBasePage')),
        mobile: lazy(() => import('./pages/mobile/my-tickets/MyTickets')),
        acceptedRole: ['admin', 'support', 'fmcgSupport']
        // authKeys: ['Ticketing']
      },
      {
        path: 'whatsapp-login',
        component: lazy(() =>
          import('./pages/desktop/whatsapp-login/WhatsappLoginPage')
        ),
        acceptedRole: ['admin']
        // authKeys: ['Whatsapp']
      },
      {
        path: 'users-list',
        component: lazy(() => import('./pages/desktop/users-list/UsersList')),
        acceptedRole: ['admin', 'support']
        // authKeys: ['ChargeSection']
      },
      {
        path: 'users-list-old',
        component: lazy(() =>
          import('./pages/desktop/user-list-old/UsersList')
        ),
        acceptedRole: ['admin', 'support']
        // authKeys: ['SystemListUser']
      },
      // {
      //   path: 'ticket-details',
      //   mobile: lazy(() => import('./pages/mobile/my-tickets/TicketDetails')),
      //   authKeys: ['Ticketing']
      // },
      {
        path: 'exchange-rates',
        component: lazy(() =>
          import('./pages/desktop/exchange-rates/ExchangeRates')
        ),
        acceptedRole: ['admin']
        // authKeys: ['ChargeSection']
      },
      {
        path: 'sale-report',
        component: lazy(() => import('./pages/desktop/sale-report/SaleReport')),
        acceptedRole: ['admin']
        // authKeys: ['ChargeSection']
      },
      {
        path: 'transaction-status',
        component: lazy(() =>
          import('./pages/desktop/transaction-status/TransactionStatus')
        ),
        acceptedRole: ['admin']
        // authKeys: ['ChargeSection']
      },
      {
        path: 'view-percentages',
        component: lazy(() =>
          import('./pages/desktop/view-percentages/viewPercentages')
        ),
        acceptedRole: ['admin']
        // authKeys: ['ChargeSection']
      },
      {
        path: 'pending-operator',
        component: lazy(() =>
          import('./pages/desktop/confirmation-operator/ConfirmationOperator')
        ),
        acceptedRole: ['admin', 'support', 'fmcgSupport']
        // authKeys: ['ChargeSection']
      },
      {
        path: 'pending-purchases',
        component: lazy(() =>
          import('./pages/desktop/purches-status/PurchesStatus')
        ),
        acceptedRole: ['admin', 'fmcgSupport']
        // authKeys: ['ChargeSection']
      },
      {
        path: 'services-management',
        component: lazy(() =>
          import('./pages/desktop/services-management/ServicesManagement')
        ),
        acceptedRole: ['admin']
        // authKeys: ['ChargeSection']
      },
      {
        path: 'daily-health-sale',
        component: lazy(() =>
          import('./pages/desktop/daily-health-sale/DailyHealthSale')
        ),
        acceptedRole: ['admin']
        // authKeys: ['ChargeSection']
      },
      {
        path: 'gardeshgari',
        component: lazy(() =>
          import('./pages/desktop/gardeshgari/GardeshgariBonCardRequest')
        ),
        authKeys: ['ChargeSection']
      },
      {
        path: 'gardeshgari-detail',
        component: lazy(() =>
          import('./pages/desktop/gardeshgari/GardeshgariBonCardRequestDetail')
        ),
        authKeys: ['ChargeSection']
      },
      {
        path: 'buy-charge-packages',
        component: lazy(() =>
          import('./pages/desktop/services-management/BuyChargePackages')
        ),
        acceptedRole: ['admin']
        // authKeys: ['ChargeSection']
      },
      {
        path: 'sample-page',
        component: lazy(() => import('./pages/SamplePage'))
      }
    ]
  },
  {
    path: '/*',
    component: lazy(() => import('./pages/desktop/errors/Error404')),
    mobile: lazy(() => import('./pages/mobile/errors/Error404'))
  }
];

export default routes;
