import { authService } from 'spec';

// Avoid using arrow functions here because of problems in functional context. (https://stackoverflow.com/questions/12995153/apply-not-working-as-expected)
// Please declare auth object concise as possible.
function defaultCheckAccess(claimNames) {
  return this.claims && claimNames.some((cn) => !!+this.claims[cn]);
}

function checkSpecificAccess(claimNames, amuKey, sluKey) {
  const amu = claimNames[amuKey] === '1';
  const slu = Boolean(claimNames[sluKey]);
  return amu || slu;
}

function defaultCheckRolePermissions(role) {
  // Ensure role is always an array
  const rolesArray = Array.isArray(role) ? role : [role];

  return rolesArray;
}

export async function GetAuthObject() {
  const claims = await authService.getClaims();
  // const role = ['d', 'd'];
  const role = await claims?.role;

  return {
    /* ********* below permissions will append to tailing of the desired permissions *********** */

    // At Least 1 claim be true --> AL1
    // Register --> R
    // Edit --> E
    // Normal Edit --> NE
    // Protected Edit --> PE
    // Register and Edit --> RE
    // Delete --> D
    // Print Information --> PI

    // *************** support claims ******************
    PersonAccountD: defaultCheckAccess.call({ claims }, ['afp.d']),
    PersonAccountPI: defaultCheckAccess.call({ claims }, ['afp.p.i']),
    PersonAccountRE: defaultCheckAccess.call({ claims }, ['afp.cou']),
    CashTransactionRE: defaultCheckAccess.call({ claims }, ['tc.cou']),
    BankTransactionRE: defaultCheckAccess.call({ claims }, ['bt.cou']),
    BranchesList: defaultCheckAccess.call({ claims }, ['ag.l']),
    TransferBetweenAccountsRE: defaultCheckAccess.call(
      {
        claims
      },
      ['t2a.cou']
    ),
    PersonAccountREorPIorD: defaultCheckAccess.call({ claims }, [
      'afp.d',
      'afp.p.i',
      'afp.cou'
    ]),
    WhatsappAccess: defaultCheckAccess.call({ claims }, ['w.a']),
    AccountUpdating: defaultCheckAccess.call({ claims }, ['a.u']),
    ChequeRE: defaultCheckAccess.call({ claims }, ['ch.cou']),
    TradeCurrencyRE: defaultCheckAccess.call({ claims }, ['bsc.cou']),
    BankAccountsList: defaultCheckAccess.call({ claims }, ['bo.d', 'bo.cou']),
    GetManagers: defaultCheckAccess.call({ claims }, ['m.c']),
    ShowCustomerProfile: defaultCheckAccess.call({ claims }, ['t.p.c']),
    Migration: defaultCheckAccess.call({ claims }, ['mig']),
    Ticketing: defaultCheckAccess.call({ claims }, ['tic']),
    UserList: defaultCheckAccess.call({ claims }, ['a.m.u']),
    ChargeSection: defaultCheckAccess.call({ claims }, ['a.m.u']),
    SystemListUser: checkSpecificAccess(claims, 'a.m.u', 's.l.u'),
    Whatsapp: defaultCheckAccess.call({ claims }, ['a.m.u']),
    Role: defaultCheckRolePermissions(role)
    // *************** end support claims ******************
  };
}

export default GetAuthObject;
